import { path } from 'libs/path'
import { Text } from 'ui/atoms'

import * as Styled from './styled'

export const Activity = ({
  onClose,
}: {
  onClose?: () => void
}) => {
  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link to={path.contests.details('letsgold')}>
        <Text level={3} weight="medium">
          Join the Let’s Gold contest!
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}
