import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { isFacebookBrowser } from 'features/oath/utils'
import { Button, Icon2, Text, Title } from 'ui/atoms'

import {
  OAuthFacebookButton,
  OAuthGoogleButton,
} from '../../../../features/oath'
import { path } from '../../../../libs/path'
import { AuthLayout } from '../../layout'
import { useRegistrationEntryPage } from './hooks'

export const RegisterEntry = () => {
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()

  const { registrationAvailability, authSocials } = useRegistrationEntryPage()

  const partnerId = searchParams.get('hwp')
  const regPromoCode = searchParams.get('regPromoCode')

  useEffect(() => {
    if (!partnerId) {
      return
    }

    localStorage.setItem('partnerId', partnerId)
  }, [])

  useEffect(() => {
    if (!regPromoCode) {
      return
    }

    localStorage.setItem('regPromoCode', regPromoCode)
  }, [])

  return (
    <AuthLayout type="register">
      {registrationAvailability.message ? (
        <ErrorText>{registrationAvailability.message}</ErrorText>
      ) : null}
      <RegisterSocialsSection>
        {authSocials.map(({ name }, key) => {
          if (name === 'google' && !isFacebookBrowser())
            return (
              <OAuthGoogleButton
                key={key}
                disabled={registrationAvailability.isForbidden}
              />
            )
          if (name === 'facebook')
            return (
              <OAuthFacebookButton
                key={key}
                disabled={registrationAvailability.isForbidden}
              />
            )
          return null
        })}
      </RegisterSocialsSection>
      {Boolean(authSocials.length) && <Or level={1}>Or</Or>}
      <RegisterEmailButton
        name="navigate-register-email"
        size="large"
        prefix={
          <Icon2
            disabled={registrationAvailability.isForbidden}
            name="envelope"
            size="medium"
          />
        }
        onClick={() => navigate(path.register.email.registration())}
        disabled={registrationAvailability.isForbidden}
      >
        Register with email
      </RegisterEmailButton>
      <SignIn>
        <SignInTitle level={4}>Already have an account?</SignInTitle>
        <SignInButton
          name="navigate-login"
          size="large"
          design="secondary"
          onClick={() => navigate(path.login.entry())}
        >
          Sign in
        </SignInButton>
      </SignIn>
    </AuthLayout>
  )
}

const RegisterSocialsSection = styled.div`
  & > button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Or = styled(Text)`
  font-weight: 500;
  text-align: center;
  margin: 32px 0;
`

const RegisterEmailButton = styled(Button)`
  width: 100%;
`

const SignIn = styled.div`
  margin-top: 80px;
  text-align: center;

  @media (max-width: 540px) {
    margin-top: 40px;
  }
`

const SignInTitle = styled(Title)`
  margin-bottom: 24px;
`

const SignInButton = styled(Button)`
  width: 100%;
`

const ErrorText = styled(Text)`
  margin-top: -20px;
  margin-bottom: 32px;
  color: var(--color-opal-500);
`
