import { path } from 'libs/path'
import { useEffect, useRef, useState } from 'react'
import { useGetExchangerWallets } from 'services/wallet/get/useGetExchangerWallets'

import { MenuItemProps } from '../../ui/molecules/menu'

export const menuItemsObj: MenuItemProps[] = [
  {
    icon: 'squareMenu',
    path: path.dashboard.entry(),
    label: 'Dashboard',
  },
  {
    icon: 'rectangleStack',
    path: path.accounts.entry(),
    label: 'My Accounts',
  },
  {
    icon: 'user',
    path: path.ib.entry(),
    label: 'IB Area',
  },
  {
    icon: 'banknotes',
    path: path.money.entry(),
    label: 'Finance',
  },
  {
    icon: 'gift',
    path: path.giftshop.entry(),
    label: 'Giftshop',
  },
  {
    icon: 'arrowsDiagonal',
    path: path.copytrade.investorEntry(),
    label: 'Copytrade',
  },
  {
    icon: 'cup',
    path: path.contests.entry(),
    label: 'Contests',
  },
  // {
  //   icon: 'newYear',
  //   path: path.your2024(),
  //   label: 'Your 2024',
  // },
]

const exchangerMenuPoint = {
  icon: 'exchange',
  path: path.exchanger.entry(),
  label: 'Exchanger Area',
} as MenuItemProps

export const useGetSidebar = () => {
  const isExchangerAreaAddRef = useRef(false)
  const [menuItems, setMenuItems] = useState(menuItemsObj)
  const {
    data: exchangerWallets,
    isLoading,
  } = useGetExchangerWallets({ noRefetch: true });

  // Exchanger Area
  useEffect(() => {
    if (isLoading) return
    
    if (!exchangerWallets?.items?.length) {
      isExchangerAreaAddRef.current = false
      return
    }

    if (isExchangerAreaAddRef.current) return

    const newMenus = [...menuItems]
    newMenus.splice(3, 0, exchangerMenuPoint);
    setMenuItems(newMenus)

    isExchangerAreaAddRef.current = true
  }, [exchangerWallets, menuItems])
  
  return menuItems
}
