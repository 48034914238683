import { enabledLangs } from './i18n'

// на сайте названия языковых папок отличаются от src/libs/i18n/langs
const getSiteLanguage = (currentLang: string): string => {
  if (enabledLangs.indexOf(currentLang) === -1 || currentLang === 'en') {
    return ''
  }

  if (currentLang === 'pt') {
    return 'pt-br/'
  }

  return `${currentLang}/`
}

export const path = {
  root: () => `/`,
  login: {
    entry: () => `/login`,
    email: () => `/login/email`,
    passwordUpdate: () => '/login/email/password-update',
  },
  register: {
    entry: () => `/signup`,
    email: {
      registration: () => `/signup/email`,
      password: () => `/signup/email/password`,
      confirm: () => `/signup/email/confirm`,
      confirmRepeat: () => `/signup/email/confirm/repeat`,
      confirmSuccess: () => `/signup/success`,
    },
    password: {
      recovery: {
        entry: () => `/password-recovery`,
        confirm: () => `/password-recovery/confirm`,
        confirmRepeat: () => `/password-recovery/confirm/repeat`,
        change: () => `/password-recovery/password`,
        success: () => `/password-recovery/success`,
      },
    },
    oOath: {
      entry: () => '/signup/oath',
    },
  },
  dashboard: {
    entry: () => `/dashboard`,
    profile: {
      entry: () => `/profile`,
      changePassword: () => `/profile/change-password`,
      loginHistory: () => `/profile/login-history`,
    },
    results: {
      yearResults2024: () => 'year-results-2024',
    },
    verification: {
      entry: () => `/profile/verification`,
      editEntry: () => '/profile/verification?edit=true',
      progress: () => `/profile/verification/progress`,
      verified: () => `/profile/verification/success`,
      updateDocs: () => `/profile/verification/update-docs`,
    },
    money: {
      withdrawal: () => '/dashboard/money/withdrawal',
    },
  },
  accounts: {
    entry: () => `/accounts`,
    moneyHistoryAccounts: () => '/transaction_history',
    details: (accountID: string) => `/accounts/${accountID}`,
    create: {
      tariff: (accountType: string) => `/accounts/create/${accountType}/tariff`,
      platform: (accountType: string) =>
        `/accounts/create/${accountType}/platform`,
      settings: (accountType: string) =>
        `/accounts/create/${accountType}/settings`,
    },
  },
  deposit: {
    entry: () => `/deposit`,
  },
  profile: {
    entry: () => `/profile`,
  },

  exchanger: {
    entry: () => '/exchanger',
    transfer: () => '/exchanger/transfer',
  },

  money: {
    entry: () => `/deposit`,
    depositForm: (id: string) => `/deposit/${id}`,
    withdrawalTab: () => '/withdrawal',
    depositTab: () => '/deposit',
    cardsTab: () => '/mycards',
    internalTransfer: () => '/internal-transfer',
    resultDeposit: () => 'money/status/deposit',
    resultWithdrawal: () => 'money/status/withdrawal',
    h2pForm: () => '/money/h2pForm',
    h2pCustom: () => 'money/h2pCustom',
    moneyHistory: () => '/transaction-history',
    customWithdrawal: (id: string) => `/withdrawal/${id}`,
  },

  ib: {
    entry: () => '/partner',
    materials: () => '/partner/admaterials',
  },

  giftshop: {
    entry: () => '/giftshop',
    details: (giftId: string) => `/giftshop/${giftId}`,
    history: () => `/giftshop/history`,
    terms: (lang: string) => `/../${getSiteLanguage(lang)}giftshop-terms`,
  },

  copytrade: {
    traderEntry: () => '/social-trading/copytrade/trader-area',
    investorEntry: () => '/social-trading/copytrade',
    createStrategy: () =>
      '/social-trading/copytrade/trader-area/create-strategy',
    compareStrategies: () => '/social-trading/copytrade/comparison',
    editStrategy: (strategyID: string) =>
      `/social-trading/copytrade/trader-area/edit-strategy/${strategyID}`,
    strategyForTrader: (strategyID: string) =>
      `/social-trading/copytrade/trader-area/strategy/${strategyID}`,
    strategyForInvestor: (strategyID: string) =>
      `/social-trading/copytrade/strategy/${strategyID}`,
    terms: (lang: string) =>
      `/../${getSiteLanguage(lang)}copytrade-social-trading-terms/`,
    howItWorks: (lang: string) => {
      switch (lang) {
        case 'ar': {
          return '/../ar/%d8%a7%d9%84%d8%aa%d8%af%d8%a7%d9%88%d9%84-%d8%a7%d9%84%d8%a7%d8%ac%d8%aa%d9%85%d8%a7%d8%b9%d9%8a/%D9%83%D9%8A%D9%81-%D8%AA%D8%B9%D9%85%D9%84-%D9%81%D9%8A-headway-copytrade/'
        }
        case 'fr': {
          return '/../fr/trading-social/comment-utiliser-headway-copytrade/'
        }
        case 'id': {
          return '/../id/social-trading-id/bagaimana-cara-menggunakan-copytrade-headway/'
        }
        case 'ja': {
          return '/../ja/%e3%82%bd%e3%83%bc%e3%82%b7%e3%83%a3%e3%83%ab%e3%83%88%e3%83%ac%e3%83%bc%e3%83%87%e3%82%a3%e3%83%b3%e3%82%b0/headway-copytrade%E3%81%AE%E4%BB%95%E7%B5%84%E3%81%BF%E3%81%A8%E3%81%AF/'
        }
        case 'ms': {
          return '/../ms/perdagangan-sosial/bagaimanakah-cara-bekerja-dengan-copytrade-headway/'
        }
        case 'pt': {
          return '/../pt-br/trading-social-pt-br/como-trabalhar-com-o-headway-copytrade/'
        }
        case 'es': {
          return '/../es/trading-social-es/como-trabajar-con-el-copytrade-de-headway/'
        }
        case 'th': {
          return '/../th/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%80%e0%b8%97%e0%b8%a3%e0%b8%94%e0%b8%aa%e0%b8%b1%e0%b8%87%e0%b8%84%e0%b8%a1/%E0%B8%88%E0%B8%B0%E0%B8%97%E0%B8%B3%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B8%81%E0%B8%9A-headway-copytrade-%E0%B9%84%E0%B8%94%E0%B8%AD%E0%B8%A2%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3/'
        }
        case 'vi': {
          return '/../vi/giao-dich-xa-hoi/cach-su-dung-headway-copytrade/'
        }
        default: {
          return '/../social-trading/how-to-work-with-headway-copytrade/'
        }
      }
    },
    startCopying: {
      selectAccount: () => '/social-trading/copytrade/account_selection',
      accountSettings: () => '/social-trading/copytrade/account_settings',
      replenishAccount: () => '/social-trading/copytrade/account_replenishment',
    },
  },

  contest: {
    traders: () => '/goldenmay_traders',
    partners: () => '/goldenmay_partners',
    terms: (lang: string) => `/../${getSiteLanguage(lang)}goldenmay-terms/`,
  },

  contests: {
    entry: () => '/demo-contests',
    details: (name: string) => `/demo-contests/${name}`,
    terms: () => '/hw-demo-contests/',
  },

  wallets: () => `/wallets`,

  trading: () => `/trading`,
  promotions: () => `promotions`,
  routing: () => `/routing`,
  terms: () => `/../client-agreement/`,
  privacy: () => `/../privacy-policy`,
  your2024: () => 'year-results-2024',

  logout: () => `/login`,
}
