import {
  differenceInCalendarDays,
  formatDuration,
  intervalToDuration,
} from 'date-fns'
import { closeBonus } from 'features/session/model'
import { useGeneratePassword } from 'libs/hooks/useGeneratePassword'
import { GeneratePassword } from 'pages/accounts/generatePassword'
import { AccountSelect } from 'pages/money/molecules/accountSelect'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAccount } from 'services/accounts/get/useGetAccount'
import { useGetBonusDestinations } from 'services/bonus/get/useGetBonusDestinations'
import { Bonus, useGetBonusInfo } from 'services/bonus/get/useGetBonusInfo'
import { useCreateBonusAccount } from 'services/bonus/mutations/useCreateBonusAccount'
import { useTransferBonus } from 'services/bonus/mutations/useTransferBonus'
import { Hint, Icon2, Input, Title } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'
import { getMetaTraderLink } from 'ui/molecules/banners/metaTraders'

import * as Styled from './styled'

const Value = ({ title, value }: { title: string; value: string }) => {
  const [t] = useTranslation()

  return (
    <Styled.ValueWrapper>
      <div className="text-gray.600">{t(title)}</div>
      <Title level={2}>{value}</Title>
    </Styled.ValueWrapper>
  )
}

export const BonusWidget = () => {
  const [t] = useTranslation()

  const [bonusData, setBonusData] = useState<Bonus>()
  const [withdrawalAmount, setWithdrawalAmount] = useState('')
  const [withdrawalDestination, setWithdrawalDestination] = useState<{
    id: string
    type: 'wallet' | 'account'
  }>()

  const { data: initialBonusData } = useGetBonusInfo()
  const { data: destinationsData } = useGetBonusDestinations()
  const {
    data: createAccountData,
    mutateAsync: createAccount,
    isLoading: createLoading,
  } = useCreateBonusAccount()
  const {
    data: transferData,
    mutateAsync: transferBonus,
    isLoading: transferLoading,
  } = useTransferBonus()

  const status = bonusData?.participantInfo?.status
  const closingAt =
    status === 'ACTIVE' && bonusData?.participantInfo.account.closingAt

  const leftTime = closingAt
    ? formatDuration(
        intervalToDuration({
          start: new Date(+closingAt * 1000),
          end: new Date(),
        }),
        {
          format:
            differenceInCalendarDays(new Date(), new Date(+closingAt)) > 1
              ? ['days']
              : ['hours', 'minutes'],
        },
      )
    : null

  const { data: accountData } = useGetAccount(
    status === 'ACTIVE'
      ? bonusData?.participantInfo.account.accountID
      : undefined,
  )

  const { password: generatedPassword } = useGeneratePassword()

  const handleWithdraw = () => {
    if (withdrawalDestination) {
      transferBonus({
        destinationType:
          withdrawalDestination.type === 'account' ? 'ACCOUNT' : 'WALLET',
        destinationID: withdrawalDestination.id,
        amount: withdrawalAmount,
      })
    }
  }

  useEffect(() => {
    if (initialBonusData) {
      setBonusData(initialBonusData)
    }
  }, [initialBonusData])

  useEffect(() => {
    if (transferData) {
      setBonusData(transferData)
      setWithdrawalAmount('')
    }
  }, [transferData])

  useEffect(() => {
    if (createAccountData) {
      setBonusData(createAccountData)
    }
  }, [createAccountData])

  return status ? (
    <Styled.Container id="bonus-111">
      {status !== 'INVALID' && status !== 'NOT_PARTICIPANT' ? (
        <Styled.RegularWrapper>
          <Styled.ActiveContent>
            <div className="w-full flex items-center justify-between">
              <Styled.BonusTitle level={3}>{t('Bonus $111')}</Styled.BonusTitle>
              {status !== 'CLOSED_ALL_PROFIT_WITHDRAWN' ? (
                <div className="flex items-center">
                  <Icon2
                    name="clock"
                    size="small"
                    className={
                      status === 'ACTIVE' ? 'text-sunray.500' : 'text-opal.500'
                    }
                  />
                  <Styled.BonusProgress>
                    {status === 'ACTIVE' ? (
                      <>
                        Bonus ends in{' '}
                        <span className="font-medium text-gray.700">
                          {leftTime}
                        </span>
                      </>
                    ) : (
                      <>
                        Bonus{' '}
                        <span className="font-medium text-gray.700">Ended</span>
                      </>
                    )}
                  </Styled.BonusProgress>
                </div>
              ) : (
                <Styled.CloseButton onClick={() => closeBonus()} />
              )}
            </div>
            {status === 'CLOSED_ALL_PROFIT_WITHDRAWN' && (
              <Styled.SuccessWrapper>
                <Title level={3}>Congratulations!</Title>
                <Styled.RegularText>
                  {t('You have withdrawn all your bonus profit.')}
                  <br />
                  {t('Keep trading with Headway.')}
                </Styled.RegularText>
                <Styled.TotalProfit>
                  <div className="text-gray.600">
                    {t('Total profit withdrawn')}
                  </div>
                  <div className="font-medium text-bronze.500">
                    ${bonusData.participantInfo.profitStatistics.totalWithdrawn}
                  </div>
                </Styled.TotalProfit>
              </Styled.SuccessWrapper>
            )}
            {(status === 'ACTIVE' || status === 'CLOSED_NO_PROFIT') && (
              <Styled.ValuesWrapper>
                <Value
                  title="Available"
                  value={toCurrency(
                    +bonusData.participantInfo.account.balance,
                    'USD',
                  )}
                />
                <Value
                  title="Profit"
                  value={toCurrency(
                    +bonusData.participantInfo.profitStatistics.availableProfit,
                    'USD',
                  )}
                />
              </Styled.ValuesWrapper>
            )}
            {status === 'ACTIVE' && (
              <>
                {accountData ? (
                  <div className="w-full">
                    <Title level={3}>MetaTrader settings</Title>
                    <Styled.SettingsWrapper>
                      <div className="flex items-center justify-between">
                        <Hint
                          textContent={t(
                            'A login for your trading account on the MetaTrader platform.',
                          )}
                        >
                          {t('Login')}
                        </Hint>
                        <div className="flex items-center font-medium">
                          {accountData.accountInfo.login}
                          <Styled.CopyIcon
                            iconSize="small"
                            copiedText={accountData.accountInfo.login}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <Hint
                          textContent={t(
                            'A password for your trading account on the MetaTrader platform.',
                          )}
                        >
                          {t('Password')}
                        </Hint>
                        <div className="flex items-center font-medium">
                          <GeneratePassword
                            password={
                              accountData.accountInfo.password ||
                              generatedPassword ||
                              ''
                            }
                            accountID={accountData.accountInfo.accountID}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <Hint
                          textContent={t(
                            'The name of Headway’s server in MetaTrader.',
                          )}
                        >
                          {t('Server')}
                        </Hint>
                        <div className="flex items-center font-medium">
                          {accountData.accountInfo.mtServerInfo.name}
                          <Styled.CopyIcon
                            iconSize="small"
                            copiedText={
                              accountData.accountInfo.mtServerInfo.name
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <Hint
                          textContent={t(
                            'The IP address of the Headway’s server in MetaTrader.',
                          )}
                        >
                          {t('Server ip')}
                        </Hint>
                        <div className="flex items-center font-medium">
                          {accountData.accountInfo.mtServerInfo.address}
                          <Styled.CopyIcon
                            iconSize="small"
                            copiedText={
                              accountData.accountInfo.mtServerInfo.address
                            }
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        MetaTrader 5
                        <Styled.DownloadLink href={getMetaTraderLink('mt5')}>
                          Download
                        </Styled.DownloadLink>
                      </div>
                    </Styled.SettingsWrapper>
                  </div>
                ) : null}
                <Styled.FixedButton
                  name="trade-now"
                  size="medium"
                  prefix={<Icon2 name="arrowTrendingUp" />}
                  href="https://webtrading.hw.site/terminal?mode=connect&utm_campaign=webterminal&utm_source=PA"
                  target="_blank"
                >
                  Trade now
                </Styled.FixedButton>
              </>
            )}
            {(status === 'CLOSED_NO_PROFIT' ||
              (status === 'CLOSING_IN_PROGRESS' &&
                +bonusData.participantInfo.profitStatistics.totalProfit ===
                  0)) && (
              <>
                <Styled.RegularText>
                  {t(
                    'The bonus period is over. Take our free course on Forex trading to keep on track.',
                  )}
                </Styled.RegularText>
                <Styled.FixedButton
                  name="take-course"
                  size="medium"
                  href="https://headway.work/courses/forex-for-beginners/"
                  target="blank"
                >
                  Take the course
                </Styled.FixedButton>
              </>
            )}
            {(status === 'CLOSING_IN_PROGRESS' ||
              status === 'CLOSED_RECENTLY_WITH_PROFIT' ||
              (status === 'CLOSED_HAS_PROFIT' &&
                +bonusData.participantInfo.profitStatistics.totalProfit >
                  0)) && (
              <>
                <Styled.ResultWrapper>
                  <div>
                    <div className="mb-[12px]">
                      <Hint
                        textContent={t(
                          'Total earned profit is the amount of money (in USD) you earn during the bonus period. Withdrawn profit is the amount you have already withdrawn.',
                        )}
                      >
                        <Title level={3}>Profit</Title>
                      </Hint>
                    </div>
                    <Styled.ValuesWrapper smallGap>
                      <Value
                        title="Withdrawn"
                        value={toCurrency(
                          +bonusData.participantInfo.profitStatistics
                            .totalWithdrawn,
                          'USD',
                        )}
                      />
                      <Value
                        title="Total earned"
                        value={toCurrency(
                          +bonusData.participantInfo.profitStatistics
                            .totalProfit,
                          'USD',
                        )}
                      />
                    </Styled.ValuesWrapper>
                  </div>
                  <div>
                    <div className="mb-[12px]">
                      <Hint
                        textContent={t(
                          'Total lots are the number of lots you need to trade to withdraw your bonus profit. Lots traded are the number of lots you have already traded for withdrawal.',
                        )}
                      >
                        <Title level={3}>Lots</Title>
                      </Hint>
                    </div>
                    <Styled.ValuesWrapper smallGap>
                      <Value
                        title="Traded"
                        value={
                          bonusData.participantInfo.profitStatistics.totalLots
                        }
                      />
                      <Value
                        title="Total"
                        value={new Intl.NumberFormat('en-US', {
                          maximumFractionDigits: 2,
                        }).format(
                          +bonusData.participantInfo.profitStatistics
                            .lotsRequired,
                        )}
                      />
                    </Styled.ValuesWrapper>
                  </div>
                </Styled.ResultWrapper>
                <div className="w-full">
                  <Styled.ResultRow>
                    <div>{t('Next month withdrawal')}</div>
                    <div className="font-medium">
                      {toCurrency(
                        +bonusData.participantInfo.profitStatistics
                          .availableWithdrawalNextMonth,
                        'USD',
                      )}
                    </div>
                  </Styled.ResultRow>
                  <Styled.ResultRow>
                    <div>{t('Traded this month')}</div>
                    <div className="font-medium">
                      {
                        bonusData.participantInfo.profitStatistics
                          .currentMonthLots
                      }{' '}
                      lots
                    </div>
                  </Styled.ResultRow>
                </div>
                {+bonusData.participantInfo.profitStatistics.availableWithdraw >
                  0 && (
                  <>
                    <div className="w-full">
                      <Styled.ResultRow>
                        <div>{t('Amount available to withdrawal')}</div>
                        <div className="font-medium">
                          {toCurrency(
                            +bonusData.participantInfo.profitStatistics
                              .availableWithdraw,
                            'USD',
                          )}
                        </div>
                      </Styled.ResultRow>
                    </div>
                    <Styled.Withdrawal>
                      <AccountSelect
                        label="Withdrawal to"
                        wallets={destinationsData?.wallets}
                        accounts={destinationsData?.accounts}
                        onSelect={setWithdrawalDestination}
                      />
                      <Input
                        value={withdrawalAmount ? `$${withdrawalAmount}` : ''}
                        onChange={(event) => {
                          const output = event.target.value
                            .replace(/[^.\d]/g, '')
                            .split('.')
                          setWithdrawalAmount(
                            output.length > 1
                              ? output.shift() + '.' + output.join('')
                              : output[0],
                          )
                        }}
                        label="Amount"
                      />
                    </Styled.Withdrawal>
                    <Styled.FixedButton
                      name="trade-now"
                      size="medium"
                      onClick={handleWithdraw}
                      disabled={
                        !withdrawalAmount ||
                        !withdrawalDestination ||
                        transferLoading ||
                        +withdrawalAmount >
                          +bonusData.participantInfo.profitStatistics
                            .availableWithdraw
                      }
                    >
                      Withdraw
                    </Styled.FixedButton>
                  </>
                )}
              </>
            )}
          </Styled.ActiveContent>
          {status !== 'CLOSED_ALL_PROFIT_WITHDRAWN' && (
            <a
              href="http://headway.work/promo/bonus-111/"
              target="_blank"
              rel="noreferrer"
              style={{ marginTop: 'auto' }}
            >
              {t('Learn more about the Bonus $111')}{' '}
              <span className="text-bronze.500">↗</span>
            </a>
          )}
        </Styled.RegularWrapper>
      ) : (
        <Styled.ColorWrapper>
          <Styled.StartContent>
            <Styled.StartTitle level={1}>
              {t('$111 Bonus for Your First Steps in Forex')}
            </Styled.StartTitle>
            <Styled.TopText>
              {t(
                'Start your trading way successfully and without initial deposit',
              )}
            </Styled.TopText>
            <Styled.GetButton
              name="get-111"
              size="medium"
              onClick={() => createAccount()}
              disabled={createLoading}
            >
              Get $111
            </Styled.GetButton>
          </Styled.StartContent>
        </Styled.ColorWrapper>
      )}
    </Styled.Container>
  ) : null
}
