import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { useOnClickOutside } from 'usehooks-ts'

import { Icon2 } from '../icon2'
import { Input, InputProps, InputState } from '../input'
import { createEvent } from 'effector'

export const selectCloseMenuEvent = createEvent();

export type ListPosition = 'left' | 'right';

export type SelectProps = {
  className?: string
  state?: InputState
  value?: string
  size?: 'small' | 'medium' | 'large' | 'base'
  options?: {
    value: string
    label: string
    hidden?: boolean
  }[]
  isHaveSearch?: boolean
  onSelect?: (value: string) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  customContent?: React.ReactNode;
  customContentComp?: React.ElementType<{onCloseSelectMenu: () => void}>;
  wichCheckActive?: boolean;
  listPosition?: ListPosition;
  inputRef?: any;
  showOptions?: boolean
  errorMessage?: string
  inputClasses?: string
  maxHeight?: number
  listClassName?: string
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  optionsWidth?: number
} & Pick<InputProps, 'name' | 'label' | 'placeholder' | 'disabled' | 'autoComplete' | 'suffix' | 'prefix' | 'inputMode' | 'readOnly'>

export const Select = ({
  name = 'default',
  className,
  options = [],
  value,
  label,
  state = 'hint',
  placeholder,
  disabled,
  size = 'medium',
  onSelect,
  onBlur,
  onChangeInput,
  isHaveSearch,
  errorMessage,
  customContent,
  customContentComp: CustomContentComp,
  listClassName,
  showOptions = true,
  listPosition = 'right',
  wichCheckActive,
  maxHeight = 260,
  optionsWidth,
  suffix,
  inputClasses,
  inputRef,
  ...rest
}: SelectProps) => {
  const [t] = useTranslation()

  const ref = React.useRef(null)

  React.useEffect(() => {
    if (isHaveSearch) return
    setActiveValue(value)
    setDropdownVisibility(false)
  }, [value])

  const [activeValue, setActiveValue] = React.useState(value)
  const [isDropdownVisible, setDropdownVisibility] = React.useState(false)
  const onCloseModal = useCallback(() =>  setDropdownVisibility(false), [])

  useOnClickOutside(ref, onCloseModal);

  const activeLabel =
    options.find((v) => {
      return v.value === activeValue
    })?.label || value

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeInput?.(e)
    if (isHaveSearch && activeValue) {
      setActiveValue('')
      onSelect?.('')
    }
  }

  useEffect(() => {
    const eventUnwatcher = selectCloseMenuEvent.watch(onCloseModal);
  
    return () => {
      eventUnwatcher();
    }
  }, [])
  

  const blurFn = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)

    if (isHaveSearch) {
      const findedValue = options.find(
        (v) => v.label?.toLocaleLowerCase() === value?.toLocaleLowerCase(),
      )?.value
      onSelect?.(findedValue ?? '')
      setActiveValue(findedValue ?? '')
    }
  }

  return (
    <>
      <SelectContainer className={className} ref={ref}>
        <SearchInput
          state={state}
          onClick={() => setDropdownVisibility(!isDropdownVisible)}
          onBlur={blurFn}
          errorMessage={errorMessage}
          name={name}
          label={label}
          value={activeLabel}
          placeholder={placeholder}
          disabled={disabled}
          inputClasses={`${inputClasses} !pe-[36px]`}
          ref={inputRef}
          suffix={
            <>
              {suffix}
              <Shevron data-disabled={disabled} className="ms-3">
                {isDropdownVisible ? (
                  <Icon2 size={size} name="chevronUp" />
                ) : (
                  <Icon2 size={size} name="chevronDown" />
                )}
              </Shevron>
            </>
          }
          {...rest}
          onChange={onChangeFn}
        />

      <OptionContainer data-visible={isDropdownVisible}>
        <OptionList
          listPosition={listPosition}
          style={{ maxHeight, width: optionsWidth }}
          className={ listClassName }
        >
          {showOptions &&
            options.map(({ value: optValue, label, hidden }, key) => (
              <Option
                key={key}
                onClick={() => {
                  setActiveValue(optValue)
                  setDropdownVisibility(false)
                  onSelect && onSelect(optValue)
                }}
                hidden={hidden}
                className={(wichCheckActive && optValue === value) ? 'text-bronze.500' : '' }
              >
                {t(label)}
                {(wichCheckActive && optValue === value) && 
                  <Icon2 name='check' className='text-bronze.500' size='base' />
                }
              </Option>
            ))}
          {customContent}
          {(!customContent && !!CustomContentComp) && <CustomContentComp onCloseSelectMenu={onCloseModal} />}
        </OptionList>
      </OptionContainer>

    </SelectContainer>
    </>
  )
}

const SearchInput = styled(Input)`
  input {
    cursor: pointer;
  }
`

const SelectContainer = styled.div``

const OptionContainer = styled.div`
  position: relative;
  top: 8px;
  transition: var(--transition);

  &[data-visible='false'] {
    opacity: 0;
    z-index: -1;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
  }
`

const OptionList = styled.div<{listPosition: ListPosition}>`
  padding: 8px 0;
  /* left: 0;
  right: 0; */
  position: absolute;
  background-color: #fff;
  box-shadow: var(--box-shadow-5);
  border-radius: 8px;
  min-height: 52px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${(p) => p.listPosition === 'right' ? css`left: 0%;` : css`right: 0;` }

  &::-webkit-scrollbar {
    display: none;
  }
`

const Option = styled.div<{ hidden?: boolean }>`
  padding: 9px 16px;
  transition: var(--transition);
  cursor: pointer;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: space-between;

  &:hover {
    background-color: var(--color-gray-200);
  }
`

const Shevron = styled.div`
  &[data-disabled='true'] {
    opacity: 0.25;
  }
`
